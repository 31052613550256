@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap');

body {
    background-color: #222;
    font-family: 'Roboto Condensed', sans-serif;
    margin-left: 200px;
}

.content {
    color: #ddd;
    margin-left: 10px;
    width: 500px;
}

.content p {
    text-align: justify;
}

.content a, a:visited {
    color: lime;
}

.content a:hover {
    color: white;
}
